function redirectToDashboard(){
    window.location = "index.php";
  }

  function setNodeVisibility(selector, visibility) {
    if(selector)
      if(visibility) $(selector).css("display","block");
      else $(selector).css("display","none");
    else return false;
    return true;
  }

  function initialUISetup() {
    $("#close").on("click", function(e) {
      e.preventDefault();
      setNodeVisibility("#mask", false);
    });
    setNodeVisibility("#formulario_login span", false); //Hides error message
  }

  function fillCaptchaForm(captchaId) {
    var loginOrigin = $("#form-login #login").val();
    var senhaOrigin = $("#form-login #senha").val();
    $("#captcha-form #login").val(loginOrigin);
    $("#captcha-form #senha").val(senhaOrigin);
    if(captchaId){
      $("#siimage").attr("src", "/extensions/securimage/captcha_show.php?captchaId=" + captchaId);
      $("#captcha-form #captchaId").val(captchaId);
    }
  }

  function setCaptchaFormToUseAjax() {
    $("#enviar-captcha").unbind("click");
    $("#enviar-captcha").on("click", function(e) {
      e.preventDefault();
      $.post("../apis/login_api.php", $("#captcha-form").serialize())
       .done(function(data) {
          dataJson = JSON.parse(data);
          if(dataJson.success == "false") {
            fillCaptchaForm(dataJson.captchaId);
            if(dataJson.message == "Supplied credentials didn't match") {
              setNodeVisibility("#mask", false);
              setNodeVisibility("#formulario_login span", true);
            }
          } else {
            redirectToDashboard();
          }
       });
    });
  }

$(document).ready(function() {
  initialUISetup();
  $("#btn_entrar").unbind("click");
  $("#btn_entrar").on("click", function(e) {
    e.preventDefault();
    setNodeVisibility("#formulario_login span", false); //Hides error message
    //Below we talk to login API, sending data and listening for response to react accordingly
    $.post("../apis/login_api.php", $("#form-login").serialize()).done(function(data) {
      var dataJson = JSON.parse(data);
      if(dataJson.success == "true") {
        redirectToDashboard();
      } else {
        //Is it time for a captcha resolution? If yes, do it.
        //If not, just tell user about an error
        fillCaptchaForm();
        if(dataJson.captchaId) {
          setNodeVisibility("#mask", true);
          fillCaptchaForm(dataJson.captchaId);
          setCaptchaFormToUseAjax();
        }else{
          setNodeVisibility("#formulario_login span", true);
        }
      }
    });
  });
});