jQuery(function($) {
  
           

  $('#contact').submit(function(e){
      e.preventDefault();
      var name = ' #name';
      var email = ' #email';
      var message = ' #message';
      var destiny = document.getElementById('destino');

      if (destiny.options[destiny.selectedIndex].value == "") { alert('Escolha o assunto'); return false;}
      if ($(name).val() == '') {alert("Insira o nome"); $(name).focus(); return false;}
      if ($(email).val() == '') {alert("Insira um email"); $(email).focus(); return false;}
      if ($(message).val() == '') {alert("Insira a mensagem"); $(message).focus(); return false;}

      getCaptcha('#contact', 'contato_envia.php');

      $.ajax({
        type: "POST",
        url: 'contato_envia.php',
        data: data,
        success: success,
        dataType: dataType
      });

      return false;
  });

});
