/*------------------Muda tamanho de fonte ----------------------*/
var tagAlvo = new Array('p'); //pega todas as tags p//
// Especificando os poss�veis tamanhos de fontes, poderia ser: x-small, small...
var tamanhos = new Array('12px', '13px', '14px', '15px', '16px', '17px', '18px');
var tamanhoInicial = 2;

function mudaTamanho(idAlvo, acao) {
    if (!document.getElementById)
        return
    var selecionados = null, tamanho = tamanhoInicial, i, j, tagsAlvo;
    tamanho += acao;
    if (tamanho < 0)
        tamanho = 0;
    if (tamanho > 6)
        tamanho = 6;
    tamanhoInicial = tamanho;
    if (!(selecionados = document.getElementById(idAlvo)))
        selecionados = document.getElementsByTagName(idAlvo)[ 0 ];

    selecionados.style.fontSize = tamanhos[ tamanho ];

    for (i = 0; i < tagAlvo.length; i++) {
        tagsAlvo = selecionados.getElementsByTagName(tagAlvo[ i ]);
        for (j = 0; j < tagsAlvo.length; j++)
            tagsAlvo[ j ].style.fontSize = tamanhos[ tamanho ];
    }
}

/*---------------------ENVIAR MATERIA PARA AMIGO---------------------*/
function conteudo_indique() {
    if (document.getElementById('indique_form').style.display == 'none') {
        document.getElementById('indique_form').style.display = '';
    } else {
        document.getElementById('indique_form').style.display = 'none';
    }
}

(function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id))
        return;
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/pt_BR/all.js#xfbml=1";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

