$(function() {
  $("#keywordb").bind({//form input:text
    click: function() {
      $textform = $(this).attr('value');
      $(this).attr('value', '');
    },
    focusout: function() {
      if ($(this).attr('value') == '') {
        $(this).attr('value', $textform);
      }
    }
  });

  // Avaliar comentário
  $('#aval a').click(function(){  
      v = $(this).attr('rel');  
      $.ajax({
      url: v,   
      success: function(result) {
        $('#avalie').empty().html(result);
        $("#avalie").show('slow');
      }
      });
      return false;
  }); 

  $('#aval a').click(function(){  
      v = $(this).attr('rel');  
      $.ajax({
      url: v,   
      success: function(result) {
        $('#avalie').empty().html(result);
        $("#avalie").show('slow');
      }
      });
      return false;
    }); 

  // Buscar no header
  $('#lupe').on('click', function() {
    $('#social').css('display', 'none');
    $('#search-form').css('display', 'block');
  });

  $('.close-search').on('click', function() {
    $('#social').css('display', 'block');
    $('#search-form').css('display', 'none');
  });

  $('#comentar').on('click', function(e) {
      e.preventDefault();
      $('#form-comment').fadeIn();
  });
  
  $('#close-form-comment').on('click', function(e) {
      e.preventDefault();
      $('#form-comment').fadeOut('slow');
  });

  // Valida enviar materia

  $('#enviar-materia').submit(function(e) {
    e.preventDefault();

    var nome = '#nome';    
    var nome_amigo = '#nome_amigo';    
    var email = '#email';    

    if ($(nome).val() == '') {alert("Insira o nome"); $(nome).focus(); return false;}
    if ($(nome_amigo).val() == '') {alert("Insira um nome do seu amigo"); $(nome_amigo).focus(); return false;} 
    if ($(email).val() == '') {alert("Insira o email"); $(email).focus(); return false;}

    getCaptcha('#enviar-materia', '/enviar.php');

    return false;

  });

  var AutoStart = setInterval(function () { moveRight(); }, 7000);
  var slideCount = $('#slider-noticias ul li').length;
  var slideWidth = $('#slider-noticias ul li').width();
  var slideHeight = $('#slider-noticias ul li').height();
  var sliderUlWidth = slideCount * slideWidth;
  
  $('#slider-noticias').css({ width: slideWidth, height: slideHeight });
  $('#slider-noticias ul').css({ width: sliderUlWidth, marginLeft: - slideWidth });
  $('#slider-noticias ul li:last-child').prependTo('#slider-noticias ul');

  function moveLeft() {
    $('#slider-noticias ul').animate({
        left: + slideWidth
    }, 200, function () {
      $('#slider-noticias ul li:last-child').prependTo('#slider-noticias ul');
      $('#slider-noticias ul').css('left', '');
    });
  };

  function moveRight() {
    $('#slider-noticias ul').animate({
        left: - slideWidth
    }, 200, function () {
        $('#slider-noticias ul li:first-child').appendTo('#slider-noticias ul');
        $('#slider-noticias ul').css('left', '');
    });
  };

  $('div.control_prev').click(function () {
    clearInterval(AutoStart);
    moveLeft();
  });

  $('div.control_next').click(function () {
    clearInterval(AutoStart);
    moveRight();
  });

  $('.lupe').on('click', function(){
    if (this.getAttribute('data-action') == 'toggle') {
      $(this).css('margin-right', '15px');
      $('#weather-forecast').toggle();
      $('#search-form').toggle();
      $('#search-form input[type=search]').css('margin-right', '15px');
      this.setAttribute('data-action', 'submit');
    } else {
      $('#search-form').submit();
    }
  });

  $('.close').on('click', function(){
      $('#weather-forecast').fadeIn();
      $('#search-form').toggle();
      $('.lupe').attr('data-action', 'toggle');
  });

  $('#botao-enviar-materia').click(function(e){
    e.preventDefault();
    $('#div-form-enviar-materia').fadeIn("slow");
  });
  $('#close-send-news').click(function(e){
    e.preventDefault();
    $('#div-form-enviar-materia').fadeOut("slow");
  });

});   

function ValidaEmail(email) {
    parte1 = email.indexOf("@");
    parte2 = email.indexOf(".");
    parte3 = email.length;
    if (!(parte1 >= 3 && parte2 >= 6 && parte3 >= 9)) {
        return false;
    }
    return(true);
}
