$(function() {
  var galleries = $('.ad-gallery').adGallery(
    defaults = {
        loader_image: '/images/default/adgallery/loader.gif',
        slideshow: {
            enable: true,
            autostart: true,
            speed: 5000,
            start_label: 'In&iacute;cio',
            stop_label: 'Para'
        },
        effect: 'fade' // or 'slide-vert', 'fade', or 'resize', 'none'
    }
  );
  $('#switch-effect').change(
    function() {
        galleries[0].settings.effect = $(this).val();
        return false;
    }
  );
  $('#toggle-slideshow').click(
    function() {
        galleries[0].slideshow.toggle();
        return false;
    }
  );
  $('#toggle-description').click(
    function() {
        if (!galleries[0].settings.description_wrapper) {
            galleries[0].settings.description_wrapper = $('#descriptions');
        } else {
            galleries[0].settings.description_wrapper = false;
        }
        return false;
    }
  );
});