/*---------------------BUSCA---------------------*/
function Busca() {
    var f = document.buscab;
    if (!f.keywordb.value) {
        alert("Informe uma palavra-chave para pesquisa!");
        f.keywordb.focus();
        return;
    }
    //window.location.href('/dados/'+$('#keywordb').val());
    f.submit();
}
