/*--------------------- Formulario de newsletter ---------------------*/
$(function()
{
    $('#mailling_form').submit(function() {
        var variavel = $('#mailling_form').serialize();

        if ($('#email_mailling').val() != '') {
            if (valida_email($('#email_mailling').val())) {
                /*$.ajax({
                 'url': 'enviar.php',
                 'type': "POST",
                 'data': variavel,
                 'success': function(data) {
                 $('#mailling_text').html('').fadeOut('fast');
                 $('#mailling_text').html(data).fadeIn('fast');
                 }
                 });*/
                submit();
            } else {
                /*$('#mailling_text').html('').fadeOut('fast');
                 $('#mailling_text').html('Email inv�lido, por favor preencha novamente.').fadeIn('fast');*/
                alert('Email inv�lido, por favor preencha novamente.');
                return false;
            }

        } else {

            /*$('#mailling_text').html('').fadeOut('fast');              
             $('#mailling_text').html('Por favor, insira um email.').fadeIn('fast'); */
            alert('Por favor, insira um email.');
            return false;
        }
        return false;
    });
});
