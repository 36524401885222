/*----------------------ENQUETE---------------------*/
function Vota(EnqTotalOp) {
    var perg = document.enquete;
    var ok = 0;
    var i = 0;
    var resp;
    var nop = EnqTotalOp;
    for (i = 0; i < nop; i++) {
        if (perg.resposta[i].checked) {
            ok++;
            resp = perg.resposta[i].value;
        }
    }
    if (ok) {
        perg.submit();
    } else {
        alert("Selecione uma op�ao");
    }
    return;
}